import React, { FC } from 'react';
import { useEnvironment, WidgetProps } from '@wix/yoshi-flow-editor';
import { noModal, ModalType, GenericModal } from '../../../types/common';
import { AlertModal } from '../../PackagePicker/Widget/AlertModal';
import List, { ListWidgetProps } from '../../PackagePicker/Widget/List';
import { EmptyStateProps } from '../../PackagePicker/Widget/List/EmptyState';
import { PlanListWidgetContext } from './PlanListWidgetContext';

export interface ListWidgetWithModalProps extends ListWidgetProps {
  modal: GenericModal<ModalType.PurchaseInPreview> | typeof noModal;
}

const Widget: FC<WidgetProps<ListWidgetWithModalProps>> = (props) => {
  const { isMobile } = useEnvironment();
  const emptyState: EmptyStateProps = { isInWidget: true };
  const width = props.host.dimensions.width ?? (isMobile ? 320 : 980);
  return (
    <PlanListWidgetContext locale={props.locale} width={width}>
      <List {...props} dataHook="PlanList-wrapper" emptyState={emptyState} />
      {props.modal.type === ModalType.PurchaseInPreview && <AlertModal {...props.modal} />}
    </PlanListWidgetContext>
  );
};

export default Widget;
