import difference from 'lodash/difference';
import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { getIds } from '../../utils/plan';
import { shouldUseVisiblePlansParam } from '../../utils/plan-visibility';
import { DefaultSettingsAdapter } from '../PackagePicker/DefaultSettingsAdapter';
import { WidgetPreset } from '../PackagePicker/settingsParams';

/**
 * This class overrides adapter settings which handle plan visibility.
 * PlanList widget handles it differently than
 * the main PackagePicker component - rather than
 * explicitly specifying hidden plans ('hiddenPlans' parameter),
 * it specifies visible ones ('visiblePlans' parameter).
 * Because of this, newly created plans are not
 * visible in the widget, until user specifies them as visible.
 *
 * When PlanList widget is created, it will still use 'hiddenPlans' paremeter
 * until migration is run (which happens on opening PlanList widget's plan settings)
 */
export class PlanListSettingsAdapter extends DefaultSettingsAdapter {
  hidePlan(id: string) {
    this.removeFromVisiblePlans(id);
  }

  unhidePlan(id: string) {
    this.addToVisiblePlans(id);
  }

  runMigrations(plans: PublicPlan[]) {
    this.migrateToVisiblePlansParameter(plans);
  }

  private addToVisiblePlans(id: string) {
    const visiblePlans = this.uuidAsArray('visiblePlans');
    this.setUuidArray('visiblePlans', visiblePlans.concat(id));
  }

  private removeFromVisiblePlans(id: string) {
    const visiblePlans = this.uuidAsArray('visiblePlans');
    this.setUuidArray(
      'visiblePlans',
      visiblePlans.filter((visibleId) => visibleId !== id),
    );
  }

  private migrateToVisiblePlansParameter(plans: PublicPlan[]) {
    if (this.isSinglePlanPreset() && plans.length > 0) {
      this.setUuidArray('visiblePlans', getIds(plans).slice(0, 1));
      this.set('preset', WidgetPreset.PLAN_LIST);
    } else if (!shouldUseVisiblePlansParam(this)) {
      const visiblePlans = difference(getIds(plans), this.uuidAsArray('hiddenPlans'));
      this.setUuidArray('visiblePlans', visiblePlans);
      this.set('hiddenPlans', '');
    }

    this.set('useVisiblePlans', true);
  }

  private isSinglePlanPreset() {
    return this.get('preset') === WidgetPreset.SINGLE_PLAN;
  }
}
