import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';

export function orderPlansForMobileView(plans: PublicPlan[], highlightedId: string) {
  const highlightedIndex = plans.findIndex((plan) => plan.id === highlightedId);
  if (highlightedIndex > 0) {
    const highlightedPlan = plans[highlightedIndex];
    return [highlightedPlan, ...plans.filter((plan) => plan !== highlightedPlan)];
  }

  return plans;
}
