import { useMemo } from 'react';
import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettingsParams } from './useSettingsParams';

export const useShouldUseMobileSettings = () => {
  const { isCssPerBreakpoint } = useEnvironment();
  const settings = useSettings();
  const settingsParams = useSettingsParams();

  return useMemo(
    () => settings.get(settingsParams.useMobileSettingsInStudio) || !isCssPerBreakpoint,
    [isCssPerBreakpoint, settings, settingsParams.useMobileSettingsInStudio],
  );
};
