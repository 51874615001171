import React, { ReactNode } from 'react';
import { WidgetContext } from '../../PackagePicker/Widget/WidgetContext';
import { PlanListSettingsAdapter } from '../PlanListSettingsAdapter';
import settingsParams from '../settingsParams';
import stylesParams from '../stylesParams';

type PlanListWidgetContextProps = {
  locale?: string;
  width: number;
  children: ReactNode;
};
export const PlanListWidgetContext: React.FC<PlanListWidgetContextProps> = ({ children, locale, width }) => {
  return (
    <WidgetContext
      settingsAdapterClass={PlanListSettingsAdapter}
      settingsParams={settingsParams}
      stylesParams={stylesParams}
      locale={locale}
      width={width}
    >
      {children}
    </WidgetContext>
  );
};
