import React from 'react';
import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { VIEWER_HOOKS } from '@wix/pricing-plans-common/hooks';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';
import { getPlanValidityCycle } from '../../../../../utils';
import { st, classes } from './Validity.st.css';

export interface ValidityProps {
  plan: PublicPlan;
  highlighted: boolean;
}

export const Validity: React.FC<ValidityProps> = ({ plan, highlighted }) => {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  return (
    <span data-hook={VIEWER_HOOKS.PLAN_DURATION} className={st(classes.duration, { highlighted, mobile: isMobile })}>
      {getPlanValidityCycle(plan, t)}
    </span>
  );
};
