import { BenefitBulletType } from '../../../../types/common';
import { Arrow } from './Arrow';
import { CircleTick } from './CircleTick';
import { HollowCircleTick } from './HollowCircleTick';
import { Tick } from './Tick';

export const iconsMap = {
  [BenefitBulletType.CIRCLE_TICK]: CircleTick,
  [BenefitBulletType.HOLLOW_CIRLCE_TICK]: HollowCircleTick,
  [BenefitBulletType.TICK]: Tick,
  [BenefitBulletType.ARROW]: Arrow,
};
