import React, { useMemo } from 'react';
import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { VIEWER_HOOKS } from '@wix/pricing-plans-common/hooks';
import { useCurrencyFormatter, useEnvironment } from '@wix/yoshi-flow-editor';
import { useDynamicElement, useIsHydrated } from '../../../../../hooks';
import { useLocale } from '../../../../../hooks/useLocale';
import { getFormattedAdditionalFees } from '../../../../../utils/get-formatted-additional-fees';
import { st, classes } from './SetupFee.st.css';

interface Props {
  plan: PublicPlan;
  planId: string;
  highlighted: boolean;
}

export const SetupFee: React.FC<Props> = ({ plan, planId, highlighted }) => {
  const { isMobile } = useEnvironment();
  const { heightByPlanId, registerRef } = useDynamicElement('setup-fee');
  const { locale } = useLocale();
  const isHydrated = useIsHydrated();

  const fees = getFormattedAdditionalFees({
    plan,
    locale,
    createCurrencyFormatter: useCurrencyFormatter,
    hideTrailingFractionZeroes: true,
  })
    .map(({ amount, name }) => `+${amount} ${name}`)
    .join('\n');

  const ref = useMemo(() => registerRef(planId), [planId, registerRef]);
  const dynamicHeight = useMemo(() => ({ height: heightByPlanId.get(planId) }), [heightByPlanId, planId]);

  return (
    <div
      className={st(classes.root, { highlighted, mobile: isMobile })}
      data-hook={VIEWER_HOOKS.PLAN_SETUP_FEE}
      // setting inline styles causes react to re-render component on hydration, so ony set after hydration
      style={!isMobile && isHydrated ? dynamicHeight : {}}
    >
      <div className={classes.content} ref={!isMobile ? ref : undefined}>
        {fees}
      </div>
    </div>
  );
};
