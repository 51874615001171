import React from 'react';
import BreakpointsProvider from '../../../hooks/BreakpointsProvider';

const BREAKPOINTS = {
  isNarrow: (width: number) => width > 0 && width <= 750,
  isExtraNarrow: (width: number) => width > 0 && width <= 450,
};

type WidgetBreakpointsProps = {
  children: React.ReactNode;
  width: number;
};

export const WidgetBreakpoints = ({ children, width }: WidgetBreakpointsProps) => (
  <BreakpointsProvider breakpoints={BREAKPOINTS} defaultWidth={width}>
    {children}
  </BreakpointsProvider>
);
