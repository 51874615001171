import React, { useMemo } from 'react';
import { VIEWER_HOOKS } from '@wix/pricing-plans-common/hooks';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useIsHydrated } from '../../../../../hooks';
import { useDynamicElement } from '../../../../../hooks/useDynamicElement';
import { st, classes } from './Tagline.st.css';

interface Props {
  planId: string;
  description: string;
  highlighted: boolean;
}

export const Tagline: React.FC<Props> = ({ planId, description, highlighted }) => {
  const { isMobile } = useEnvironment();
  const { registerRef, heightByPlanId } = useDynamicElement('tagline');
  const isHydrated = useIsHydrated();

  const ref = useMemo(() => registerRef(planId), [planId]);
  const dynamicHeight = useMemo(() => ({ height: heightByPlanId.get(planId) }), [heightByPlanId, planId]);

  return (
    <div
      data-hook={VIEWER_HOOKS.PLAN_TAGLINE}
      className={st(classes.tagline, { highlighted, mobile: isMobile })}
      // setting inline styles causes react to re-render component on hydration, so ony set after hydration
      style={!isMobile && isHydrated ? dynamicHeight : {}}
    >
      <div ref={!isMobile ? ref : undefined}>{description}</div>
    </div>
  );
};
